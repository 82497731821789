import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfiledetailsService {
  httpOptions={
    headers: new HttpHeaders({
      'Content-Type':'application/json'
    })
  }
  constructor(private http:HttpClient) {
  
    
      
    }
    GetUserById(accessKey,Id): Observable<any>{

      return this.http.get(environment.apiURL+'Support/ProfileList?Key='+ accessKey+'&Source='+Id)
      .pipe(catchError(this.handelerror));
   
   }
   handelerror(error: HttpErrorResponse) {
    return throwError(error);
  }

  AccessUserAccount(access):Observable<any>{
    return this.http.post(environment.apiURL+'Support/AccessAccount',JSON.stringify(access),this.httpOptions)
      .pipe(catchError(this.handelerror));
  }
}
