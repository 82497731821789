import { OnInit, ChangeDetectorRef, Pipe, PipeTransform, NgModule, AfterViewInit, Directive } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';
import { UserdetailsService } from './userdetails.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Component } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, MatLabel, matSortAnimations, MatProgressBar, MatIconRegistry } from '@angular/material';
import { ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../auth/service/notification.service';
import { TimeElapsedPipe } from './../../../core/_base/layout/pipes/time-elapsed.pipe';
import { LoaderService } from '../auth/service/loader.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { select } from '@ngrx/store';





@Component({  
  selector: 'kt-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})

export class UserlistComponent implements OnInit {

  userDetails: PeriodicElement[] = [];
  userDetail: PeriodicElement[] = [];
  userDetailListByFilter: PeriodicElement[] = [];
  displayedColumns: string[] = ['id', 'name', 'phoneNumber', 'email', 'country', 'plan', 'nextRenewalDate', 'noOfProfile', 'isExpired','actions'];
  dataSource: any;
  planlist: string[] = [];
  planname: any;
  selectedplanName:any;
  public index = 0;
  accessKey: any;
  p: number = 1;
  selectedValue: any;
  filterdOptions = [];
  plan: any;
  planDetails: any;
  ToastrService: any;
  response = [];
  isHidden: boolean = true;
  expiry: string;

  showLoadingIndicator = true;
  dt: any; 
  dataDisplay: any;
  isLoading = false;
  public accessData: AccessUserReqData;

  constructor(private userService: UserdetailsService,
    public cdr: ChangeDetectorRef,
    public pageservice: PageConfigService, 
    private toastr: NotificationService,
    private loaderService: LoaderService,
    private http: HttpClient) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  select = false;
  ngOnInit() {
    this.getAllUserData();
    this.isLoading = true;
    setTimeout( () => this.isLoading = false, 2000 );

    // this.http.get('http://www.mocky.io/v2/5ec6a61b3200005e00d75058')  .subscribe(Response => { 
                        
                     
    //                   if (Response) { 
    //                       hideloader(); 
    //                   } this.accessKey = localStorage.getItem(environment.authTokenKey);

    // this.userService.Getallusers(this.accessKey).subscribe((res) => {
    //   if(res['success']){
    //     hideloader();
    //   }
    //   console.log(Response)
    //   this.dt = Response;
    //   this.dataDisplay = this.dt.data;
    //   this.isLoading = false;
    // });
    // function hideloader() {
    //   document.getElementById('loading')
    //     .style.display = 'none';
    // }  
  }
  getAllUserData() {
    this.pageservice.showLoader();
    this.isLoading=true;
    console.log('planname');
    console.log('expiry');
    this.accessKey = localStorage.getItem(environment.authTokenKey);
    this.userService.Getallusers(this.accessKey).subscribe((res) => {
      // debugger;
      if (res['success']) {
        this.pageservice.hideLoader();
        this.userDetails = res['data'];
        for (var list of this.userDetails) {
           this.planname = list['plan'];
          this.planlist.push(this.planname);
          this.planlist = removeDumplicateValue(this.planlist);
        }
        
        this.dataSource = new MatTableDataSource<any>(this.userDetails);
        this.filterExpired(false);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading=false;
        this.cdr.markForCheck();
        
      }
      else {
        
        this.isLoading=false;
        if (res['errorMsg'].toLocaleLowerCase() == environment.sessiontimeourMsg) {
       
          setTimeout(() => {
            this.pageservice.sessionExpired();
          }, environment.sessionTimeOur)
        }
      }
    }, (err) => {
      err.status = 400;
      this.toastr.showError(err.error.errorMsg, 'Error')
      this.logout();
    });
    // ,(err) => this.toastr.showError('HTTP Error', Error))
    // .pipe(catchError(this.handelerror));

  }

  logout() {
    localStorage.clear();
    location.reload();
  }
  errorMsg(errorMsg: any) {
    // debugger;
    errorMsg.status = 400;
    this.toastr.showError(errorMsg.error.errorMsg, 'Error')
    // throw new Error('Method not implemented.');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  planFilter(expiry, planname) {
    
    if (expiry != null && planname == null) {
      console.log(expiry)
      this.userDetailListByFilter = this.userDetails.filter((item) => item['isExpired'] == expiry);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);
    }
    if (planname != null && expiry == null) {
      this.userDetailListByFilter= this.userDetails.filter((item) => item['plan'] == planname);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);

    }
    if (planname != null && expiry != null) {
      this.userDetailListByFilter = this.userDetails.filter((item) => item['plan'] == planname && item['isExpired'] == expiry);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.markForCheck();
    if (expiry == null && planname == null) {
      // this.getAllUserData();
      this.dataSource = new MatTableDataSource<any>(this.userDetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading=false;
      this.cdr.markForCheck();
    }
  }
  filterData(event: any): void {
       if (event.value != undefined) {
      this.isLoading=true;
      this.selectedplanName = event.value;
      console.log(this.expiry);
      this.planFilter(this.expiry, this.selectedplanName);
      this.isLoading=false;
     
    }
    else {
      this.isLoading=true;
      this.selectedplanName = null;
      this.planFilter(this.expiry, this.selectedplanName);
      // this.dataSource = new MatTableDataSource<any>(this.userDetails);
      this.isLoading=false;
    }
  }
  filterExpired(event: any) {
    if (event != undefined) {
      this.isLoading=true;
      this.expiry = event;
      console.log(event)
      this.planFilter(this.expiry, this.selectedplanName);
      this.isLoading=false;
    }
    else {
      console.log('null',event)
      this.isLoading=true;
      this.expiry = null;
      this.planFilter(this.expiry, this.selectedplanName);
      this.isLoading=false;
    }
  }

  isLoder(){
    // debugger;
    this.accessKey = localStorage.getItem(environment.authTokenKey);
    this.userService.Getallusers(this.accessKey).subscribe((res) => {
      if(res['success']){
        hideloader();
      }
      console.log(Response) 
      this.dt = Response; 
      this.dataDisplay = this.dt.data; 

      this.isLoading=false; 
  });   

  }

  Accessuseraccount(profileid, source) {
    this.accessData = new AccessUserReqData();
    this.accessData.Key = localStorage.getItem(environment.authTokenKey);
    this.accessData.ProfileId = profileid;
    this.accessData.Source = source;
    this.userService.AccessUserAccount(this.accessData).subscribe(res => {
      if (res['success'] == true) {
        window.open("https://app.grabyourreviews.com/auth/login?sso="+ res['key'],"_blank");
      }
    },
      (error) => {
        // console.log(error.error);
      })
  }

}
function removeDumplicateValue(myArray) {
  var newArray = [];
  var isadded = false;
  myArray.forEach(arry => {
    newArray.forEach(na => {
      if (na == arry) {
        isadded = true;
      }
    });
    if (isadded == false)
      newArray.push(arry);
  });
  return newArray;
}

function hideloader() { 
  document.getElementById('loading') 
      .style.display = 'none'; 
} 

export interface PeriodicElement {
  id: number;
  name: string;
  phoneNumber: string;
  email: string;
  country: string;
  plan: string;
  nextRenewalDate: Date;
  noOfProfile: number;
  isExpired: boolean;
}

export class AccessUserReqData {
  Key: string;
  Source: string;
  ProfileId: string;
  IPAddress: string;
  Device: string;
  Browser: string;
}










