import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ProfileDetails } from '../userlist/userdetails.service';
import { ProfiledetailsService } from './profiledetails.service';
import { environment } from './../../../../environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { strict } from 'assert';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { negate, List } from 'lodash';
import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core/testing';
import { PeriodicElement } from '../userlist/userlist.component';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../auth/service/notification.service';
import { PageConfigService } from './../../../core/_base/layout/services/page-config.service';
import { Router } from '@angular/router';
import { from, Subscription } from 'rxjs';
import { LoaderService } from './../auth/service/loader.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'kt-profilelist',
  templateUrl: './profilelist.component.html',
  styleUrls: ['./profilelist.component.scss'],
  animations: [

  ],
})
export class ProfilelistComponent implements OnInit, OnDestroy {
  // public UserProfile: profileList[] = [];
  public UserProfile: [];
  ListByFilter: nedata[] = [];
  displayedColumns: string[] = ['source', 'userName', 'planName', 'profileName','expired','country', 'lastReviewReq','expiryDate', "actions"];
  dataSource: any;
  accessKey: any;
  Id: any;
  planName: any;
  profiledata: profileList[];
  List: nedata[] = [];
  public nedatamodel: nedata;
  public accessData: AccessUserReqData;
  userDetails: PeriodicElement[] = [];
  planlist: string[] = [];
  isLoading = false;
  dt: any;
  dataDisplay: any;
  loadingSubscription: Subscription;


  constructor(private userProfileService: ProfiledetailsService, private toastr: NotificationService,
    public pageservice: PageConfigService, private router: Router, private http: HttpClient,
    public cdr: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document,
    ) { }



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit() {

    // this.isLoading = true;
    setTimeout(() => this.isLoading = false, 2000);
    this.getUserById()
    
    //   this.accessKey = localStorage.getItem(environment.authTokenKey);
    //   this.Id = '';
    //   this.userProfileService.GetUserById(this.accessKey, this.Id).subscribe((res) => {
    //     debugger;
    //     if (res['success']) {
    //       hideloader();
    //     }
    //     console.log(Response)
    //     this.dt = Response;
    //     this.dataDisplay = this.dt.data;
    //     this.isLoading = false;
    //   });
    //   function hideloader() {
    //     document.getElementById('loading')
    //       .style.display = 'none';
    //   }  
  }
  ngOnDestroy(): void { }
  load(): void {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, 2000);
  }

  profileExpired : any;
  getUserById() {
    
    this.isLoading = true;
    this.accessKey = localStorage.getItem(environment.authTokenKey);
    this.Id = '';
    this.pageservice.showLoader();
    this.userProfileService.GetUserById(this.accessKey, this.Id).subscribe((res) => {
     
      if (res['success']) {
        
        // profile is expired or not

        for(let i in res.profileList){
          for(let j in res.profileList[i].data){
            this.profileExpired = res.profileList[i].data[j].isExpired
          }
        }
        this.pageservice.hideLoader();
        this.userDetails = res['profileList'];
        for (var list of this.userDetails) {
          this.planName = list['planName'];
          this.planlist.push(this.planName);
          this.planlist = removeDumplicateValue(this.planlist);
        }
        
        this.UserProfile = JSON.parse(JSON.stringify(res['profileList']));
        for (var data of this.UserProfile) {
          
          this.profiledata = JSON.parse(JSON.stringify(data['data']));

          if (this.profiledata != null) {
            console.log("wo",this.profiledata)
            for (var iterator of this.profiledata) {
              this.nedatamodel = new nedata();
              this.nedatamodel.userName = data['userName'];
              this.nedatamodel.source = data['source'];
              this.nedatamodel.planName = data['planName'];
              this.nedatamodel.profileName = iterator["profileName"];
              this.nedatamodel.country = iterator["country"];
              this.nedatamodel.profileId = iterator['profileId'];
              this.nedatamodel.lastReviewReq = iterator['lastReviewReq'];
              this.nedatamodel.expiryDate = iterator["expiryDate"];
              this.nedatamodel.expired = iterator["isExpired"]
              // if(this.profileExpired){
              //   this.nedatamodel.expired = "Yes";
              // }else{
              //   this.nedatamodel.expired = "No";
              // }
              
              
              //debugger;
              this.List.push(this.nedatamodel);
            
              
            }
          }
          else {
            this.nedatamodel = new nedata();
            this.nedatamodel.userName = data['userName'];
            this.nedatamodel.source = data['source'];
            this.nedatamodel.planName = data['planName'];
            this.nedatamodel.expired = this.profileExpired;
            this.List.push(this.nedatamodel);
          }
        }
        
        this.dataSource = new MatTableDataSource<any>(this.List);
        this.filterExpiry(false);
        console.log("d",this.dataSource)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.cdr.markForCheck();
      }
      else {
        // console.log(res['errorMsg'])
        this.isLoading = false;
        if (res['errorMsg'].toLocaleLowerCase() == environment.sessiontimeourMsg) {
          // debugger;
          setTimeout(() => {
            this.pageservice.sessionExpired();
          }, environment.sessionTimeOur)
        }
      }
    }, (err) => {
      err.status = 400;
      this.toastr.showError(err.error.errorMsg, 'Error')
      this.logout();
      // alert(err.error.errorMsg)
      // this.toastr.showError('err.errorMsg', Error)
    });
  }

  logout() {
    localStorage.clear();
    location.reload();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // filterData(event: any) {
  //   if (event.value != undefined) {
  //     debugger;
  //     this.List = this.List.filter((item) => item['planName'] == event.value);
  //     this.dataSource = new MatTableDataSource<any>(this.List);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     this.cdr.markForCheck();
  //   }
  //   else {
  //     this.getUserById();
  //   }
  // }

  sortData() {

   // console.log(this.userDetails);

    // for (var list of this.userDetails) {
    //   this.planName = list['planName'];
    //   this.planlist.push(this.planName);
    //   if(this.planName != null)
    //   {
    //     for (var data of this.UserProfile) {
    //       //get user profile list
    //       // console.log(data['userName']);
    //       this.nedatamodel = new nedata();
    //       this.nedatamodel.userName = data['userName'];
    //       this.nedatamodel.source = data['source'];
    //       this.nedatamodel.planName = data['planName'];
    //       this.profiledata = JSON.parse(JSON.stringify(data['data']));

    //       if (this.profiledata != null) {
    //         for (var iterator of this.profiledata) {
    //           this.nedatamodel.profileName = iterator["profileName"];
    //           this.nedatamodel.profileId = iterator['profileId'];
    //           this.nedatamodel.lastReviewReq = iterator['lastReviewReq'];
    //           this.List.push(this.nedatamodel.profileName);
    //         }
    //       }
    //       else {
    //         this.List.push(this.nedatamodel);
    //       }
    //     }
    //   }
    // }
  }

  userDetailListByFilter: PeriodicElement[] = [];

  planFilter(expiry, planname) {
    
    if (expiry != null && planname == null) {
      console.log(expiry)
      this.userDetailListByFilter = this.userDetails.filter((item) => item['isExpired'] == expiry);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);
    }
    if (planname != null && expiry == null) {
      this.userDetailListByFilter= this.userDetails.filter((item) => item['plan'] == planname);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);

    }
    if (planname != null && expiry != null) {
      this.userDetailListByFilter = this.userDetails.filter((item) => item['plan'] == planname && item['isExpired'] == expiry);
      this.dataSource = new MatTableDataSource<any>(this.userDetailListByFilter);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.markForCheck();
    if (expiry == null && planname == null) {
      // this.getAllUserData();
      this.dataSource = new MatTableDataSource<any>(this.userDetails);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading=false;
      this.cdr.markForCheck();
    }
  }

  selectedplanName : any;
  expiry : any = false;
  filterData(event: any): void {
    //debugger;
    if (event.value != undefined) {
      // this.getAllUserData();
      this.isLoading = true;
      this.selectedplanName = event.value;
      this.planFilter(this.expiry, this.selectedplanName);
      // debugger;
      // this.ListByFilter = this.List.filter((item) => item['planName'] == event.value);
      // console.log(this.ListByFilter)
      // this.dataSource = new MatTableDataSource<any>(this.ListByFilter);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      // this.cdr.markForCheck();
      this.isLoading = false;
    }
    else {
      this.dataSource = new MatTableDataSource<any>(this.List);
      this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.cdr.markForCheck();
    }
  }

  filterExpiry(event: any): void {
    //debugger;
    console.log(event)
    if (event != undefined) {
      // this.getAllUserData();
      this.isLoading = true;
      // debugger;
      this.ListByFilter = this.List.filter((item) => item['expired'] == event);
      console.log(this.ListByFilter)
      this.dataSource = new MatTableDataSource<any>(this.ListByFilter);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.cdr.markForCheck();
      this.isLoading = false;
    }
    else {
      this.dataSource = new MatTableDataSource<any>(this.List);
      this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.cdr.markForCheck();
    }
  }

  isLoder() {

    this.accessKey = localStorage.getItem(environment.authTokenKey);
    this.Id = '';
    this.userProfileService.GetUserById(this.accessKey, this.Id).subscribe((res) => {
      //debugger;
      if (res['success']) {
        hideloader();
      }
      console.log(Response)
      this.dt = Response;
      this.dataDisplay = this.dt.data;

      this.isLoading = false;
    });

  }
  Accessuseraccount(profileid, source) {
    this.accessData = new AccessUserReqData();
    this.accessData.Key = localStorage.getItem(environment.authTokenKey);
    this.accessData.ProfileId = profileid;
    this.accessData.Source = source;
    this.userProfileService.AccessUserAccount(this.accessData).subscribe(res => {
      if (res['success'] == true) {
        window.open("https://app.grabyourreviews.com/auth/login?sso="+ res['key'],"_blank");
      }
    },
      (error) => {
        // console.log(error.error);
      })
  }
  
}
function hideloader() {
  document.getElementById('loading')
    .style.display = 'none';
}



function removeDumplicateValue(myArray) {
  var newArray = [];
  var isadded = false;
  myArray.forEach(arry => {
    newArray.forEach(na => {
      if (na == arry) {
        isadded = true;
      }
    });
    if (isadded == false)
      newArray.push(arry);
  });
  return newArray;
}

export interface profileList {
  source: number;
  userName: string;
  planName: string;
  data?: Details[] | MatTableDataSource<Details>;
}
export class nedata {
  source: string;
  userName: string;
  planName: string;
  profileName: string;
  profileId: string;
  lastReviewReq: string;
  multipleLocation: string;
  country: string;
  expiryDate: string;
  expired: string
}

export interface Details {
  profileName: string;
  profileId: number;
  lastReviewReq: Date;
  expiryDate: Date;
  country: string;
  multipleLocation: string;
}

export class AccessUserReqData {
  Key: string;
  Source: string;
  ProfileId: string;
  IPAddress: string;
  Device: string;
  Browser: string;
}