// Angular
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UserlistComponent } from './userlist/userlist.component';
import { ProfilelistComponent } from './profilelist/profilelist.component';
import { MatLabel, MatOptionModule, MatSelectModule, MatSpinner, MatTableModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatSortModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { ToastrModule } from 'ngx-toastr'; 


@NgModule({
//	declarations: [UserlistComponent],
	exports: [
	],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		MatInputModule,
		CoreModule,
		PartialsModule,
		//MailModule,
		//ECommerceModule,
		//UserManagementModule,
		MatTableModule,
		BrowserAnimationsModule,
		MatSortModule,
		MatPaginatorModule,
		MatButtonModule,
		MatOptionModule,
		MatSelectModule,
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule, // required animations module
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
			enableHtml: true
		}), // ToastrModule added
	],
	entryComponents:[UserlistComponent],
	declarations: [UserlistComponent, ProfilelistComponent],
	bootstrap:[UserlistComponent],
	providers: [{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue:
		{ appearance: 'fill' } },
	],	
})
export class PagesModule {
	
}

