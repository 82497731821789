import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../auth/service/notification.service';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserdetailsService {
  httpOptions={
    headers: new HttpHeaders({
      'Content-Type':'application/json'
    })
  }
  constructor(private http: HttpClient, private toastr:NotificationService) { }

  Getallusers(accessKey): Observable<any> {
    
    return this.http.get(environment.apiURL + 'support/userlist?Key=' + accessKey )
      .pipe(catchError(this.handelerror));

  }
  handelerror(error: HttpErrorResponse) {
    return throwError(error);
    
  }

  
  AccessUserAccount(access):Observable<any>{
    return this.http.post(environment.apiURL+'Support/AccessAccount',JSON.stringify(access),this.httpOptions)
      .pipe(catchError(this.handelerror));
  }
}

export class UserDetails {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  country: string;
  plan: string;
  nextRenewalDate: string;
  isExpired: boolean;
  noOfProfile:number;
}

export class ProfileDetails {
  id: string;
  name: string;
  shortURL: string;
}
